import {bindActionCreators, Store} from 'redux'
import {setFormFactor} from '../../../../commons/actions/environment'
import {Actions} from '../types'
import {setActiveElement} from '../../../../commons/actions/focus-handler'
import {
  setShowPricingOptionsPlace,
  setShowMobileBasket,
  setShowAccessibilityMode,
  setShowPlaceDescription,
  selectPlace,
  unselectPlace,
} from '../reducers/seating/mode'
import {selectPrice, selectLocation, resetFilters} from '../reducers/seating/filters'
import {setSeatingError, setPlaceDonationError} from '../reducers/seating/errors'
import {updatePlace} from '../reducers/seating/places'
import {addToCalendarClicked} from './calendar'
import {
  checkout,
  clearCheckout,
  editStep,
  getDiscount,
  handleNextStep,
  nextFormClicked,
  setExpandedTicketIndex,
  setTicketDetails,
  setUseBuyerDetails,
  setValidPaymentAdded,
  submitCheckoutStep,
} from './checkout'
import {publishComponentSettings, updateStyleParams} from './component'
import {applyCoupon, resetCouponCode} from './coupon'
import {getMembers, shareEvent} from './event'
import {clearInvoice} from './invoice'
import {detailsPageLoaded, formPageLoaded, thankYouPageLoaded, ticketedThankYouPageLoaded} from './loaded'
import {ensureLoginForMembersOnly, openMembersModal, promptLogin} from './members'
import {
  closeNativeModal,
  openCantCompletePaymentModal,
  openCheckoutUnavailable,
  openRecurringEventsModal,
  openSeatingPlan,
  openTicketsDetailsModal,
  openTicketsDownloadModal,
  openTimeExpiredModal,
} from './modals'
import {
  navigate,
  navigateToChangeRsvp,
  navigateToDetails,
  navigateToForm,
  navigateToMainPage,
  navigateToOrder,
  navigateToTicketsPicker,
  navigateViaEditor,
} from './navigation'
import {downloadTickets} from './order-success'
import {applyPlan, getPlanList, openMembershipPicker} from './paid-plans'
import {paymentMethodSelected, placeOrderButtonClicked} from './payment'
import {getOrder, placeOrder, updateOrder} from './placed-order'
import {openPolicyModal, toggleAgreePolicies} from './policies'
import {registrationButtonClicked} from './registration'
import {cancelReservation, clearReservationError, reserveTickets} from './reservation'
import {
  changeMemberRsvp,
  changeResponse,
  editRsvpStep,
  handleNextRsvpStep,
  handleRSVP,
  resetRsvpError,
  sendRsvp,
  submitRsvpStep,
  updateRsvp,
} from './rsvp'
import {navigateToSchedulePage} from './schedule'
import {changeTicketDonation, selectTicket, setDonationError, unselectTicket} from './selected-tickets'
import {updateSettings} from './settings'
import {collapseDescription, expandDescription} from './tickets-picker'
import {joinGroup} from './groups'
import {setPlaceQuantity, addPlaceDonation, clickPlace, selectPlacePricingOption} from './seating/place'
import {seatingMapButtonClick} from './seating/bi'
import {seatingCheckout} from './seating/checkout'

export const getExportedActions = (store: Store): Actions => {
  const dispatchActions = {
    navigateToForm,
    navigateToMainPage,
    detailsPageLoaded,
    formPageLoaded,
    thankYouPageLoaded,
    ticketedThankYouPageLoaded,
    changeMemberRsvp,
    registrationButtonClicked,
    updateStyleParams,
    resetCouponCode,
    resetRsvpError,
    sendRsvp,
    updateRsvp,
    reserveTickets,
    selectTicket,
    unselectTicket,
    ensureLoginForMembersOnly,
    expandDescription,
    collapseDescription,
    getMembers,
    getPlanList,
    openMembershipPicker,
    promptLogin,
    navigateToTicketsPicker,
    openCheckoutUnavailable,
    getOrder,
    updateOrder,
    placeOrder,
    paymentMethodSelected,
    placeOrderButtonClicked,
    navigateToDetails,
    nextFormClicked,
    openTicketsDetailsModal,
    openTicketsDownloadModal,
    cancelReservation,
    applyCoupon,
    addToCalendarClicked,
    applyPlan,
    handleRSVP,
    checkout,
    submitCheckoutStep,
    editStep,
    openTimeExpiredModal,
    openCantCompletePaymentModal,
    setValidPaymentAdded,
    navigate,
    navigateViaEditor,
    setUseBuyerDetails,
    setTicketDetails,
    clearInvoice,
    navigateToOrder,
    navigateToChangeRsvp,
    setExpandedTicketIndex,
    handleNextStep,
    openMembersModal,
    updateSettings,
    publishComponentSettings,
    clearCheckout,
    getDiscount,
    downloadTickets,
    shareEvent,
    toggleAgreePolicies,
    openPolicyModal,
    submitRsvpStep,
    handleNextRsvpStep,
    editRsvpStep,
    changeResponse,
    setFormFactor,
    closeNativeModal,
    navigateToSchedulePage,
    openRecurringEventsModal,
    joinGroup,
    openSeatingPlan,
    setActiveElement,
    setPlaceQuantity,
    updatePlace,
    selectPrice,
    selectLocation,
    setShowAccessibilityMode,
    selectPlace,
    unselectPlace,
    setSeatingError,
    resetFilters,
    seatingMapButtonClick,
    clearReservationError,
    changeTicketDonation,
    setDonationError,
    addPlaceDonation,
    setPlaceDonationError,
    setShowPricingOptionsPlace,
    setShowMobileBasket,
    setShowPlaceDescription,
    clickPlace,
    seatingCheckout,
    selectPlacePricingOption,
  }

  return {
    ...bindActionCreators(dispatchActions, store.dispatch),
  }
}
