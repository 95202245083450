import {createAction} from '@reduxjs/toolkit'
import {isDonationLessThanMinimum} from '@wix/wix-events-commons-statics'
import {DONATION_ERROR} from '../types'
import {createAsyncAction} from '../services/redux-toolkit'
import {getTicketById} from '../selectors/tickets'
import {getSelectedTicketQuantity, isTicketLimitReached} from '../selectors/selected-tickets'
import {isEmptyDonation} from '../services/donations'

export interface SelectUnselectTicket {
  ticketId: string
  count: number
}

export interface ChangeTicketDonation {
  ticketId: string
  donation: string
}

export interface ChangeTicketDonationResponse {
  ticketId: string
  donation: string
  quantity: number
}

export interface SetDonationError {
  ticketId: string
  error: DONATION_ERROR
}

export const selectTicket = createAction<SelectUnselectTicket>('SELECT_TICKET')

export const unselectTicket = createAction<SelectUnselectTicket>('UNSELECT_TICKET')

export const changeTicketDonation = createAsyncAction<ChangeTicketDonationResponse, ChangeTicketDonation>(
  'CHANGE_TICKET_DONATION',
  async ({ticketId, donation}, {getState}) => {
    const state = getState()
    const definition = getTicketById(state.tickets, ticketId)
    const selectedQuantity = getSelectedTicketQuantity(state, ticketId)

    let quantity = selectedQuantity
    if (isEmptyDonation(donation) || isDonationLessThanMinimum(definition, Number(donation))) {
      quantity = 0
    } else if (!selectedQuantity && !isTicketLimitReached(state)) {
      quantity = 1
    }

    return {
      ticketId,
      donation,
      quantity,
    }
  },
)

export const setDonationError = createAction<SetDonationError>('SET_DONATION_ERROR')
